<template>
  <div class="page">
    <section class="section-1">
      <div class="content">
        <div class="title">商务合作</div>
        <div class="chin">
          请完成以下表单，方便我们在1-3个工作日内联系到您。
        </div>
        <div class="form">
          <div class="item-row-1">
            <div class="item">
              <p class="label">
                <span>* 1.加盟主体</span>
                <span v-if="subjectTypeWarning" class="warning">
                  请选择加盟主体！
                </span>
              </p>
              <div class="select-row">
                <div class="select">
                  <div class="select-item" @click="checkSelect('INDIVIDUAL')">
                    <div
                      :class="{
                        'select-box': true,
                        'select-box-right': true,
                        'select-box-action': subjectType === 'INDIVIDUAL',
                      }"
                    >
                      个人
                    </div>
                  </div>
                  <div class="select-item" @click="checkSelect('COMPANY')">
                    <div
                      :class="{
                        'select-box': true,
                        'select-box-left': true,
                        'select-box-action': subjectType === 'COMPANY',
                      }"
                    >
                      公司
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <p class="label">
                <span v-if="subjectType !== 'INDIVIDUAL'">* </span>
                <span>2.企业名称</span>
                <span style="color: #555555" v-if="subjectType === 'INDIVIDUAL'"
                  >【个人可不填写】</span
                >
                <span v-if="companyNameWarning" class="warning">
                  请输入企业名称！
                </span>
              </p>
              <div class="input-row">
                <input
                  class="input"
                  placeholder="请输入企业名称"
                  type="text"
                  maxlength="20"
                  v-model="companyName"
                />
              </div>
            </div>
          </div>
          <div class="item-row-2">
            <div class="row-2-col-1">
              <div class="item">
                <p class="label">
                  <span>* 3.从什么渠道了解到啦撒</span>
                  <span v-if="radioValueWarning" class="warning">
                    请选择渠道！
                  </span>
                </p>
                <div class="radio-row">
                  <div class="radio">
                    <div
                      v-for="(item, index) in radioList"
                      class="radio-item"
                      :key="index"
                      @click="checkRadio(item.value)"
                    >
                      <div
                        :class="{
                          'radio-circle': true,
                          'radio-circle-action': radioValue === item.value,
                        }"
                      >
                        <div
                          class="radio-mini-circle"
                          v-show="radioValue === item.value"
                        />
                      </div>
                      <div>{{ item.text }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <p class="label">
                  <span>* 5.联系人</span>
                  <span v-if="nameWarning" class="warning">请输入联系人！</span>
                </p>
                <div class="input-row">
                  <input
                    class="input"
                    placeholder="请输入联系人"
                    type="text"
                    maxlength="10"
                    v-model="name"
                  />
                </div>
              </div>
              <div class="item">
                <p class="label">
                  <span>* 6.手机号码</span>
                  <span v-if="phoneWarning" class="warning"
                    >请输入手机号码！</span
                  >
                  <span v-if="submitted" class="warning">手机号已提交！</span>
                </p>
                <div class="input-row">
                  <input
                    class="input"
                    placeholder="请输入手机号码"
                    type="text"
                    maxlength="11"
                    v-model="phone"
                  />
                </div>
              </div>
              <div class="item mt20">
                <div class="code-row">
                  <input
                    class="input input-code"
                    placeholder="请输入验证码"
                    type="text"
                    maxlength="6"
                    v-model="code"
                  />
                  <div
                    :class="{
                      'get-code': true,
                      'get-code-disabled': timingNum < 60,
                    }"
                    @click="checkGetCode"
                  >
                    {{ timingNum > 59 ? "获取验证码" : timingNum + "s" }}
                  </div>
                </div>
                <p v-if="codeWarning" class="warning warning-code-position">
                  请输入验证码！
                </p>
                <p v-if="codeError" class="warning warning-code-position">
                  验证码有误！
                </p>
              </div>
            </div>
            <div class="row-2-col-2">
              <div class="item">
                <p class="label">
                  <span>* 4.设备安装场景</span>
                  <span style="color: #555555">【多选】</span>
                  <span v-if="checkBoxValueWarning" class="warning">
                    请选择安装场景！
                  </span>
                </p>
                <div class="check-box-row">
                  <div class="check-box">
                    <div
                      v-for="(item, index) in checkBoxList"
                      class="check-box-item"
                      :key="index"
                      @click="checkCheckBox(item.value)"
                    >
                      <div
                        :class="{
                          box: true,
                          'box-action':
                            (checkBoxValue ^ item.value) < checkBoxValue,
                        }"
                      />
                      <div>{{ item.text }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          :class="{ submit: true, disSubmit: submitState }"
          @click="onSubmit"
        >
          <span v-if="!submitState">提交</span>
          <span v-if="submitState">已提交</span>
        </div>
      </div>
    </section>
    <section class="section-2">
      <div class="content">
        <div class="title">售后服务</div>
        <div class="line">
          <div class="line-color" />
        </div>
        <div class="box">
          <div class="service-list">
            <div class="item" v-for="(item, index) in serviceList" :key="index">
              <img
                :src="
                  require('../../assets/images/join/' + item.imgUrl + '.svg')
                "
                alt=""
              />
              <p class="title">{{ item.title }}</p>
              <div class="text">
                <p>{{ item.text1 }}</p>
                <p>{{ item.text2 }}</p>
                <p>{{ item.text3 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="confirmShow" class="mask" @click="handleClickConfirm">
      <div class="content">
        <img src="../../assets/images/join/confirm.svg" alt="" />
        <div class="text">
          <p class="text-title">提交成功</p>
          <p class="text-prompt">请您耐心等待，我们将在3个工作日内联系您</p>
        </div>
        <div class="btn">知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinIndex",
  data() {
    return {
      serviceList: [
        {
          imgUrl: "service-1",
          title: "培训支持",
          text1: "产品培训",
          text2: "运维培训",
          text3: "销售培训",
        },
        {
          imgUrl: "service-2",
          title: "运营支持",
          text1: "案例分享",
          text2: "行业解决方案",
          text3: "",
        },
        {
          imgUrl: "service-3",
          title: "产品支持",
          text1: "产品定期更新",
          text2: "软硬件不定期升级",
          text3: "",
        },
        {
          imgUrl: "service-4",
          title: "物料支持",
          text1: "产品资料",
          text2: "推广资料等",
          text3: "",
        },
        {
          imgUrl: "service-5",
          title: "服务支持",
          text1: "专属客服，渠道经理",
          text2: "实时无缝对接",
          text3: "及时提供解决方案",
        },
      ],
      // 表单值
      name: undefined,
      phone: undefined,
      code: undefined,
      subjectType: "COMPANY",
      companyName: undefined,
      radioValue: 0,
      checkBoxValue: 0,
      // 控制警告文字
      nameWarning: false,
      phoneWarning: false,
      submitted: false,
      codeWarning: false,
      codeError: false,
      subjectTypeWarning: false,
      companyNameWarning: false,
      radioValueWarning: false,
      checkBoxValueWarning: false,
      // 其他变量
      submitState: false,
      confirmShow: false,
      radioList: [
        {
          text: "公众号",
          value: 1,
        },
        {
          text: "小程序",
          value: 2,
        },
        {
          text: "卫生间",
          value: 3,
        },
        {
          text: "同事/朋友",
          value: 4,
        },
        {
          text: "其他",
          value: 10,
        },
      ],
      checkBoxList: [
        {
          text: "商城",
          value: 1,
        },
        {
          text: "写字楼",
          value: 2,
        },
        {
          text: "机场",
          value: 4,
        },
        {
          text: "高铁",
          value: 8,
        },
        {
          text: "医院",
          value: 16,
        },
        {
          text: "市政中心",
          value: 32,
        },
        {
          text: "其他",
          value: 64,
        },
      ],
      timingNum: 60,
    };
  },
  methods: {
    checkGetCode() {
      this.submitted = false;
      this.codeError = false;
      const reg = new RegExp("^1[3456789]\\d{9}$", "g");
      if (!this.phone || !reg.test(this.phone)) {
        this.phoneWarning = true;
        return;
      } else {
        this.phoneWarning = false;
      }
      if (this.timingNum === 60) {
        this.timingNum = 59;
        let interval = setInterval(() => {
          this.timingNum--;
          if (this.timingNum === 0) {
            clearInterval(interval);
            this.timingNum = 60;
          }
        }, 1000);
        this.postAxios("intention/phone/note", {
          phone: this.phone,
        }).then((data) => {
          if (data.code === 4501) {
            // TODO
            this.$notice({ type: "warning", message: "请勿频繁发送" });
          }
          if (data.code === 4511) {
            this.submitted = true;
          }
        });
      }
    },
    checkSelect(value) {
      this.subjectType = value;
      this.companyNameWarning = false;
    },
    checkRadio(value) {
      this.radioValue = value;
    },
    checkCheckBox(value) {
      this.checkBoxValue = this.checkBoxValue ^ value;
    },
    onConfirmShow(value) {
      const mo = function (e) {
        e.preventDefault();
      };
      if (value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = ""; //出现滚动条
      }
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    handleClickConfirm() {
      this.confirmShow = false;
      this.onConfirmShow(false);
    },
    onSubmit() {
      this.submitted = false;
      this.codeError = false;
      const reg = new RegExp("^1[3456789]\\d{9}$", "g");
      let isCanSubmit = true;
      if (!this.name) {
        this.nameWarning = true;
        isCanSubmit = false;
      } else {
        this.nameWarning = false;
      }
      if (!this.phone || !reg.test(this.phone)) {
        this.phoneWarning = true;
        isCanSubmit = false;
      } else {
        this.phoneWarning = false;
      }
      if (!this.code) {
        this.codeWarning = true;
        isCanSubmit = false;
      } else {
        this.codeWarning = false;
      }
      if (!this.subjectType) {
        this.subjectTypeWarning = true;
        isCanSubmit = false;
      } else {
        this.subjectTypeWarning = false;
      }
      if (this.subjectType === "COMPANY" && !this.companyName) {
        this.companyNameWarning = true;
        isCanSubmit = false;
      } else {
        this.companyNameWarning = false;
      }
      if (!this.radioValue) {
        this.radioValueWarning = true;
        isCanSubmit = false;
      } else {
        this.radioValueWarning = false;
      }
      if (!this.checkBoxValue) {
        this.checkBoxValueWarning = true;
        isCanSubmit = false;
      } else {
        this.checkBoxValueWarning = false;
      }
      if (isCanSubmit) {
        this.postAxios("intention/add", {
          name: this.name,
          phone: this.phone,
          code: this.code,
          subjectType: this.subjectType,
          companyName: this.companyName,
          sources: this.radioValue,
          useScene: this.checkBoxValue,
        }).then((data) => {
          if (data.code === 0) {
            this.submitState = true;
            this.confirmShow = true;
            this.onConfirmShow(true);
          }
          if (data.code === 4511) {
            this.submitted = true;
          }
          if (data.code === 4512) {
            this.codeError = true;
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.section-1 {
  width: 100vw;
  min-height: 742px;
  height: calc(100vh - 63px);
  background: linear-gradient(180deg, #feffff 0%, #e9ecf1 100%);
  display: flex;
  align-items: center;
  .content {
    margin: 0 auto;
    width: 1000px;
    height: 702px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      height: 28px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    }
    .chin {
      margin-top: 20px;
      height: 20px;
      font-size: 14px;
      color: #555555;
      line-height: 20px;
    }
    .form {
      margin-top: 10px;
      width: 100%;
      .item-row-1 {
        display: flex;
        justify-content: space-between;
      }
      .item-row-2 {
        display: flex;
        justify-content: space-between;
      }
    }
    .item {
      position: relative;
      width: 480px;
      margin-top: 32px;
      .label {
        height: 28px;
        font-size: 18px;
        color: #555555;
        line-height: 28px;
      }
      .input,
      .radio,
      .check-box {
        background: rgba(255, 255, 255, 1);
        width: 480px;
        height: 44px;
        border-radius: 4px;
        border: 0.5px solid #999999;
        outline-style: none;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        padding: 0 16px;
        box-sizing: border-box;
      }
      .input-row,
      .select-row,
      .code-row,
      .radio-row,
      .check-box-row {
        position: relative;
        margin-top: 8px;
      }
      .code-row {
        display: flex;
        .get-code {
          cursor: pointer;
          margin-left: 10px;
          width: 120px;
          height: 44px;
          background: #fe690c;
          border-radius: 4px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
        }
        .get-code-disabled {
          background: #d8d8d8;
          color: #555555;
        }
      }
      .input-code {
        margin-top: 0;
        width: 240px;
      }
      .input::-webkit-input-placeholder {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
      .input:focus {
        border: 1px solid #fe690c;
      }
      .select {
        width: 480px;
        position: relative;
        display: flex;
        align-items: center;
        background: #ffffff;
        .select-item {
          cursor: pointer;
          width: 240px;
          height: 44px;
          display: flex;
          align-items: center;
        }
        .select-box {
          width: 240px;
          height: 44px;
          border: 1px solid #999999;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .select-box-left {
          border-radius: 0 4px 4px 0;
        }
        .select-box-right {
          border-radius: 4px 0 0 4px;
        }
        .select-box-action {
          border: 1px solid #fe690c;
          background: #fe690c;
          color: #ffffff;
        }
      }
      .check-box {
        height: 308px;
      }
      .radio {
        height: 44px;
        display: flex;
        align-items: center;
      }
      .radio-item {
        position: relative;
        margin-left: 24px;
        height: 22px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        .radio-circle {
          margin-right: 3px;
          width: 22px;
          height: 22px;
          border: 1px solid #999999;
          border-radius: 50%;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          .radio-mini-circle {
            width: 14px;
            height: 14px;
            background: #fe690c;
            border-radius: 50%;
          }
        }
        .radio-circle-action {
          width: 22px;
          height: 22px;
          border: 1px solid #fe690c;
          border-radius: 50%;
          box-sizing: border-box;
        }
      }
      .radio-item:nth-child(1) {
        margin-left: 0;
      }
      .check-box-item {
        position: relative;
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        .box {
          margin-right: 10px;
          width: 22px;
          height: 22px;
          border-radius: 4px;
          box-sizing: border-box;
          border: 1px solid #999999;
        }
        .box-action {
          background: #fe690c url("../../assets/images/join/success.png")
            no-repeat center center;
          background-size: 14px 10px;
          border: 1px solid #fe690c;
        }
      }
      .check-box-item:nth-child(n + 2)::before {
        content: "";
        width: 480px;
        height: 0.5px;
        background: #999999;
        position: absolute;
        top: -2px;
        left: -17px;
      }
      .warning {
        margin-left: 10px;
        font-size: 14px;
        color: #ff0000;
      }
      .warning-code-position {
        position: absolute;
        bottom: -20px;
      }
    }
    .mt20 {
      margin-top: 20px;
    }
    .submit {
      cursor: pointer;
      margin-top: 70px;
      width: 240px;
      height: 44px;
      background: #fe690c;
      box-sizing: border-box;
      border-radius: 4px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .disSubmit {
      background: #d8d8d8;
      color: #555555;
    }
  }
}
.section-2 {
  width: 100vw;
  min-height: 600px;
  height: calc(100vh - 250px);
  background: #ffffff;
  display: flex;
  align-items: center;
  .content {
    margin: 0 auto;
    width: 1200px;
    height: 332px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      height: 28px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    }

    .line {
      margin-top: 40px;
      width: 1200px;
      height: 4px;
      background: #f7f7f7;
      display: flex;
      justify-content: center;

      .line-color {
        width: 284px;
        height: 4px;
        background: #fe690c;
      }
    }

    .box {
      margin-top: 40px;
      width: 1200px;
      height: 220px;
      .service-list {
        width: 1200px;
        height: 220px;
        display: flex;
        justify-content: space-around;
        .item {
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 96px;
            height: 96px;
          }
          .title {
            margin-top: 20px;
            height: 28px;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            line-height: 28px;
          }
          .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
            height: 66px;
            font-size: 14px;
            font-weight: 400;
            color: #555555;
            line-height: 22px;
          }
        }
      }
    }
  }
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    position: relative;
    width: 355px;
    height: 239px;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-top: 26px;
      width: 48px;
      height: 48px;
    }
    .text {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text-title {
        height: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #555555;
        line-height: 28px;
      }
      .text-prompt {
        height: 20px;
        font-size: 14px;
        color: #555555;
        line-height: 20px;
      }
    }
    .btn {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      width: 355px;
      height: 50px;
      background: #fe690c;
      border-radius: 0 0 20px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
</style>
