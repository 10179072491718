var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('section',{staticClass:"section-1"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("商务合作")]),_c('div',{staticClass:"chin"},[_vm._v(" 请完成以下表单，方便我们在1-3个工作日内联系到您。 ")]),_c('div',{staticClass:"form"},[_c('div',{staticClass:"item-row-1"},[_c('div',{staticClass:"item"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("* 1.加盟主体")]),(_vm.subjectTypeWarning)?_c('span',{staticClass:"warning"},[_vm._v(" 请选择加盟主体！ ")]):_vm._e()]),_c('div',{staticClass:"select-row"},[_c('div',{staticClass:"select"},[_c('div',{staticClass:"select-item",on:{"click":function($event){return _vm.checkSelect('INDIVIDUAL')}}},[_c('div',{class:{
                      'select-box': true,
                      'select-box-right': true,
                      'select-box-action': _vm.subjectType === 'INDIVIDUAL',
                    }},[_vm._v(" 个人 ")])]),_c('div',{staticClass:"select-item",on:{"click":function($event){return _vm.checkSelect('COMPANY')}}},[_c('div',{class:{
                      'select-box': true,
                      'select-box-left': true,
                      'select-box-action': _vm.subjectType === 'COMPANY',
                    }},[_vm._v(" 公司 ")])])])])]),_c('div',{staticClass:"item"},[_c('p',{staticClass:"label"},[(_vm.subjectType !== 'INDIVIDUAL')?_c('span',[_vm._v("* ")]):_vm._e(),_c('span',[_vm._v("2.企业名称")]),(_vm.subjectType === 'INDIVIDUAL')?_c('span',{staticStyle:{"color":"#555555"}},[_vm._v("【个人可不填写】")]):_vm._e(),(_vm.companyNameWarning)?_c('span',{staticClass:"warning"},[_vm._v(" 请输入企业名称！ ")]):_vm._e()]),_c('div',{staticClass:"input-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyName),expression:"companyName"}],staticClass:"input",attrs:{"placeholder":"请输入企业名称","type":"text","maxlength":"20"},domProps:{"value":(_vm.companyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.companyName=$event.target.value}}})])])]),_c('div',{staticClass:"item-row-2"},[_c('div',{staticClass:"row-2-col-1"},[_c('div',{staticClass:"item"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("* 3.从什么渠道了解到啦撒")]),(_vm.radioValueWarning)?_c('span',{staticClass:"warning"},[_vm._v(" 请选择渠道！ ")]):_vm._e()]),_c('div',{staticClass:"radio-row"},[_c('div',{staticClass:"radio"},_vm._l((_vm.radioList),function(item,index){return _c('div',{key:index,staticClass:"radio-item",on:{"click":function($event){return _vm.checkRadio(item.value)}}},[_c('div',{class:{
                        'radio-circle': true,
                        'radio-circle-action': _vm.radioValue === item.value,
                      }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.radioValue === item.value),expression:"radioValue === item.value"}],staticClass:"radio-mini-circle"})]),_c('div',[_vm._v(_vm._s(item.text))])])}),0)])]),_c('div',{staticClass:"item"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("* 5.联系人")]),(_vm.nameWarning)?_c('span',{staticClass:"warning"},[_vm._v("请输入联系人！")]):_vm._e()]),_c('div',{staticClass:"input-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"input",attrs:{"placeholder":"请输入联系人","type":"text","maxlength":"10"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}})])]),_c('div',{staticClass:"item"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("* 6.手机号码")]),(_vm.phoneWarning)?_c('span',{staticClass:"warning"},[_vm._v("请输入手机号码！")]):_vm._e(),(_vm.submitted)?_c('span',{staticClass:"warning"},[_vm._v("手机号已提交！")]):_vm._e()]),_c('div',{staticClass:"input-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"input",attrs:{"placeholder":"请输入手机号码","type":"text","maxlength":"11"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}})])]),_c('div',{staticClass:"item mt20"},[_c('div',{staticClass:"code-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"input input-code",attrs:{"placeholder":"请输入验证码","type":"text","maxlength":"6"},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.code=$event.target.value}}}),_c('div',{class:{
                    'get-code': true,
                    'get-code-disabled': _vm.timingNum < 60,
                  },on:{"click":_vm.checkGetCode}},[_vm._v(" "+_vm._s(_vm.timingNum > 59 ? "获取验证码" : _vm.timingNum + "s")+" ")])]),(_vm.codeWarning)?_c('p',{staticClass:"warning warning-code-position"},[_vm._v(" 请输入验证码！ ")]):_vm._e(),(_vm.codeError)?_c('p',{staticClass:"warning warning-code-position"},[_vm._v(" 验证码有误！ ")]):_vm._e()])]),_c('div',{staticClass:"row-2-col-2"},[_c('div',{staticClass:"item"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("* 4.设备安装场景")]),_c('span',{staticStyle:{"color":"#555555"}},[_vm._v("【多选】")]),(_vm.checkBoxValueWarning)?_c('span',{staticClass:"warning"},[_vm._v(" 请选择安装场景！ ")]):_vm._e()]),_c('div',{staticClass:"check-box-row"},[_c('div',{staticClass:"check-box"},_vm._l((_vm.checkBoxList),function(item,index){return _c('div',{key:index,staticClass:"check-box-item",on:{"click":function($event){return _vm.checkCheckBox(item.value)}}},[_c('div',{class:{
                        box: true,
                        'box-action':
                          (_vm.checkBoxValue ^ item.value) < _vm.checkBoxValue,
                      }}),_c('div',[_vm._v(_vm._s(item.text))])])}),0)])])])])]),_c('div',{class:{ submit: true, disSubmit: _vm.submitState },on:{"click":_vm.onSubmit}},[(!_vm.submitState)?_c('span',[_vm._v("提交")]):_vm._e(),(_vm.submitState)?_c('span',[_vm._v("已提交")]):_vm._e()])])]),_c('section',{staticClass:"section-2"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("售后服务")]),_vm._m(0),_c('div',{staticClass:"box"},[_c('div',{staticClass:"service-list"},_vm._l((_vm.serviceList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('img',{attrs:{"src":require('../../assets/images/join/' + item.imgUrl + '.svg'),"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(item.text1))]),_c('p',[_vm._v(_vm._s(item.text2))]),_c('p',[_vm._v(_vm._s(item.text3))])])])}),0)])])]),(_vm.confirmShow)?_c('div',{staticClass:"mask",on:{"click":_vm.handleClickConfirm}},[_vm._m(1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"line-color"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('img',{attrs:{"src":require("../../assets/images/join/confirm.svg"),"alt":""}}),_c('div',{staticClass:"text"},[_c('p',{staticClass:"text-title"},[_vm._v("提交成功")]),_c('p',{staticClass:"text-prompt"},[_vm._v("请您耐心等待，我们将在3个工作日内联系您")])]),_c('div',{staticClass:"btn"},[_vm._v("知道了")])])}]

export { render, staticRenderFns }